import React from 'react'
import { IconButton } from '@jsluna/react'
import { ArrowLeft } from '@jsluna/icons'

interface BackButtonProps {
  text?: string
}

const BackButton: React.FC<BackButtonProps> = ({
  text = 'Search other clubs',
}) => {
  return (
    <IconButton variant="text" label={text} fullWidth>
      <ArrowLeft />
    </IconButton>
  )
}

export default BackButton
