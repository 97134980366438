import React, { useLayoutEffect } from 'react'
import { useParams, useNavigate } from '@reach/router'
import {
  FilledButton,
  FieldInfo,
  Label,
  TextInput,
  Heading,
  Container,
  GridWrapper,
  GridItem,
} from '@jsluna/react'
import { useForm } from 'react-hook-form'
import venueSearchSchema from '@active-kids/common/validation/venueSearchSchema'
import { useQuery } from 'react-query'
import Axios from 'axios'
import VenuesListing from '../VenuesListing'
import Loader from '../Loader'
import useRedirect from '../useRedirect'
import config from '../../config'

const searchVenues = async (_key: string, postcode: string) => {
  const { data } = await Axios.get(
    `${config.apiEndpoint}${config.venuesPath}?search=${postcode}`
  )
  return data
}

type FormData = {
  location: string
}

const VenuesSearch: React.FC = () => {
  useRedirect()
  const params = useParams()
  const navigate = useNavigate()
  const { register, handleSubmit, watch, errors, setValue } = useForm<FormData>(
    {
      validationSchema: venueSearchSchema,
    }
  )
  const { data, error, status } = useQuery(
    params?.postcode && ['course', params?.postcode],
    searchVenues
  )
  const locationInputValue = watch('location', '')
  useLayoutEffect(() => {
    if (params?.postcode) {
      setValue('location', params.postcode)
    }
  }, [])
  const onSubmit = handleSubmit(async ({ location }) => {
    navigate(`/venues/${location}`, { replace: false })
  })

  const errorMessage = errors?.location?.message
  return (
    <>
      <Container size="xs">
        <Heading level={2} className="ln-u-text-align-center">
          Find clubs in your area
        </Heading>
        <form onSubmit={onSubmit}>
          <Label htmlFor="location">Location</Label>
          <GridWrapper verticalAlign="center" className="location_search">
            <GridItem size={{ sm: '3/4' }}>
              <TextInput
                placeholder="Enter town or postcode"
                name="location"
                type="text"
                maxLength={128}
                innerRef={register()}
                error={errorMessage}
              />
            </GridItem>
            <GridItem className="" size={{ sm: '1/4' }}>
              <FilledButton
                disabled={locationInputValue.length <= 2}
                fullWidth
                type="submit"
              >
                Search
              </FilledButton>
            </GridItem>
          </GridWrapper>
          {errorMessage && <FieldInfo error>{errorMessage}</FieldInfo>}
        </form>
      </Container>
      <Container size="sm" className="ln-u-margin*2 activities__wrapper">
        <Loader show={status === 'loading'} />
        {error && <div>An error has ocurred</div>}
        <VenuesListing venues={data?.data} />
      </Container>
    </>
  )
}

export default VenuesSearch
