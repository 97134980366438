import React from 'react'
import { FilledButton, Link } from '@jsluna/react'

interface BookingButtonProps {
  course: {
    isFull: boolean
  }
  url: string
}

const Button: React.FC<{ isFull?: boolean }> = ({ children, isFull }) => (
  <FilledButton
    disabled={isFull}
    className="booking_button"
    fullWidth
    data-testid="booking-button"
  >
    {children}
  </FilledButton>
)

const BookingButton: React.FC<BookingButtonProps> = ({ course, url }) => {
  const { isFull } = course
  if (isFull) {
    return (
      <>
        <Button isFull>No spaces available</Button>
      </>
    )
  }
  return (
    <Link href={url} bare style={{ width: '100%' }}>
      <Button>Book now</Button>
    </Link>
  )
}

export default BookingButton
