import React from 'react'
import { Router } from '@reach/router'
import { Card, Section } from '@jsluna/react'
import SEO from '../components/Seo'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import './venues.scss'
import VenuesSearch from '../components/VenuesSearch'
import Venue from '../components/Venue'

const Venues = () => {
  return (
    <Layout testid="venues-page">
      <SEO title="Venues Search" />
      <Section className="page">
        <Hero />
        <Section className="page_content">
          <Card>
            <Router basepath="/venues" primary={false}>
              <Venue path="/venue/:id" />
              <VenuesSearch path="/:postcode" />
              <VenuesSearch default />
            </Router>
          </Card>
        </Section>
      </Section>
    </Layout>
  )
}
export default Venues
