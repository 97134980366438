const getLastDay = (date: Date, endDate: Date) => {
  if (date.getMonth() === endDate.getMonth()) {
    return endDate.getDate()
  }
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
}

const getCalendarViewData = (startDate: string, endDate: string) => {
  const end = new Date(endDate)
  const calendarViewData = []
  let loop = new Date(startDate)
  let currentMonth
  let month

  while (loop <= end) {
    month = loop.getMonth()
    if (currentMonth !== month) {
      currentMonth = month
      calendarViewData.push({
        month,
        startDay: new Date(loop).getDate(),
        endDay: getLastDay(loop, end),
        year: loop.getFullYear(),
      })
    }
    loop = new Date(loop.setDate(loop.getDate() + 1))
  }

  return calendarViewData
}

export default getCalendarViewData
