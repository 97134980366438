import React from 'react'
import { GoogleMap, useLoadScript } from '@react-google-maps/api'
import Loader from '../Loader/Loader'
import MapMarker from './MapMarker'
import config from '../../config'

interface Venue {
  id: number
  name: string
  address: string
  lat: number
  lon: number
}

interface MapVenuesProps {
  venues: Venue[]
  showInfo: boolean
  height?: number
}

const MapVenues: React.FC<MapVenuesProps> = ({
  venues,
  showInfo,
  height = 500,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.googleMapsKey,
  })

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  if (!isLoaded) return <Loader show />

  return (
    <GoogleMap
      mapContainerClassName="ln-u-margin-top*2"
      mapContainerStyle={{
        height: `${height}px`,
        width: '100%',
      }}
      zoom={12}
      center={{ lat: venues[0].lat, lng: venues[0].lon }}
    >
      {venues.map(({ lat, lon, name, address, id }) => {
        return (
          <MapMarker
            key={id}
            id={id}
            lat={lat}
            lng={lon}
            name={name}
            address={address}
            showInfo={showInfo}
          />
        )
      })}
    </GoogleMap>
  )
}

export default MapVenues
