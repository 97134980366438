import React from 'react'
import { GridWrapper, GridItem, Display2 } from '@jsluna/react'
import BookingButton from '../BookingButton'
import Map from '../Map'
import config from '../../config'

interface Venue {
  id: number
  name: string
  address: string
  lat: number
  lon: number
  courses: any[]
}

interface InfoVenueProps {
  venue: Venue
}

const VenueInfo: React.FC<InfoVenueProps> = ({ venue }) => {
  const { name, address, courses } = venue
  return (
    <GridWrapper matrix>
      <GridItem size={{ sm: '1/2' }}>
        <Display2 element="h3">{name}</Display2>
        <GridItem className="pl0 venue_address" size={{ sm: '1/2' }}>
          {address.split(',').map((p) => (
            <p key={p} className="address_part">
              {p}
            </p>
          ))}
          <BookingButton
            course={{ isFull: courses[0].full }}
            url={`${config.premierSiteCourse}${courses[0].id}`}
          />
        </GridItem>
      </GridItem>
      <GridItem size={{ sm: '1/2' }}>
        <Map venues={[venue]} showInfo={false} height={300} />
      </GridItem>
    </GridWrapper>
  )
}

export default VenueInfo
