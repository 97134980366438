import React from 'react'

interface CalendarEmptyDaysProps {
  numberOfDays: number
}

const CalendarEmptyDays: React.FC<CalendarEmptyDaysProps> = ({
  numberOfDays,
}) => {
  const n = (numberOfDays + 7) % 7
  return (
    <>
      {[...Array(n)].map((_e, i) => (
        <span
          key={`${i + 1}`}
          className="day unused"
          data-testid="calendar-empty-day"
        />
      ))}
    </>
  )
}

export default CalendarEmptyDays
