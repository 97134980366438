import React from 'react'
import { Link } from '@reach/router'
import { Card, FilledButton, GridWrapper, GridItem } from '@jsluna/react'

interface VenueProps {
  id: number
  name: string
  address: string
  distance: number
  isClosest?: boolean
}

const Venue: React.FC<VenueProps> = ({
  id,
  name,
  address,
  distance,
  isClosest = false,
}) => {
  const closestClassname = isClosest
    ? 'activity_list_item closest'
    : 'activity_list_item'
  return (
    <Card
      className={closestClassname}
      data-testid={isClosest ? 'venue-closest' : 'venue'}
    >
      <GridWrapper verticalAlign="middle">
        <GridItem size={{ sm: '2/5' }}>
          <GridItem size="1/1" className="pl0">
            <p className="m0 activity_venue_name">
              <strong>{name}</strong>
            </p>
            <p className="m0 small_text">{address}</p>
          </GridItem>
        </GridItem>
        <GridItem size={{ sm: '2/5' }} style={{ height: '100%' }}>
          <>{distance && <strong>{`${distance.toFixed(2)} miles`}</strong>}</>
        </GridItem>
        <GridItem
          style={{ textAlign: 'right' }}
          size={{ default: '1/2', sm: '1/5' }}
        >
          <Link to={`/venues/venue/${id}`}>
            <FilledButton
              fullWidth
              type="button"
              className="activity_list_button"
            >
              View venue
            </FilledButton>
          </Link>
        </GridItem>
      </GridWrapper>
    </Card>
  )
}

export default Venue
