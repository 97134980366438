import React, { useState } from 'react'
import { GridWrapper, GridItem, Heading } from '@jsluna/react'
import { ListView, StoreLocation } from '@jsluna/icons'
import Cookies from 'js-cookie'
import VenueCard from '../VenueCard'
import ToggleView from '../ToggleView'
import Map from '../Map'

const buttons = [
  { label: 'List view', value: 'list', icon: ListView },
  { label: 'Map view', value: 'map', icon: StoreLocation },
]

interface Venue {
  id: number
  name: string
  address: string
  courses_running: number
  distance: number
  lat: number
  lon: number
}

interface VenuesListingProps {
  venues?: Venue[]
}

const VenuesListing: React.FC<VenuesListingProps> = ({ venues }) => {
  const [state, setState] = useState(
    Cookies.get('venues_view') ?? buttons[0].value
  )
  const toggleHandler = (toggleState: string) => {
    Cookies.set('venues_view', toggleState)
    setState(toggleState)
  }
  const toggle = {
    list: state === buttons[0].value,
    map: state === buttons[1].value,
  }

  if (!venues) return null
  if (venues.length === 0) {
    return (
      <Heading level={4} style={{ textAlign: 'center' }}>
        There is no results for that postcode, please search again with another
        postcode
      </Heading>
    )
  }
  const [closestVenue, ...otherVenues] = venues
  return (
    <>
      <GridWrapper matrix verticalAlign="center">
        <GridItem size="1/1" style={{ textAlign: 'right' }}>
          <ToggleView
            state={state}
            buttons={buttons}
            onChangeEvent={toggleHandler}
          />
        </GridItem>
      </GridWrapper>
      <GridWrapper matrix verticalAlign="center">
        {toggle.list && (
          <GridItem size="1/1" data-testid="venues_list">
            <Heading level={4}>Closest</Heading>
            <VenueCard
              key={closestVenue.id}
              id={closestVenue.id}
              name={closestVenue.name}
              address={closestVenue.address}
              distance={closestVenue.distance}
              isClosest
            />
            {!!otherVenues.length && (
              <>
                <Heading level={5}>
                  Other schools and venues near your area
                </Heading>
                {otherVenues.map((venue) => (
                  <VenueCard
                    key={venue.id}
                    id={venue.id}
                    name={venue.name}
                    address={venue.address}
                    distance={venue.distance}
                  />
                ))}
              </>
            )}
          </GridItem>
        )}

        {toggle.map && (
          <GridItem size="1/1" data-testid="venues_map">
            <Map venues={venues} showInfo />
          </GridItem>
        )}
      </GridWrapper>
    </>
  )
}

export default VenuesListing
