import React from 'react'
import {
  GridWrapper,
  GridItem,
  Display1,
  Body1,
  FilledButton,
} from '@jsluna/react'
import { Link } from '@reach/router'

interface MapInfoProps {
  name: string
  address: string
  id: number
}

const MapInfo: React.FC<MapInfoProps> = ({ name, address, id }) => {
  return (
    <GridWrapper className="map_info_window" verticalAlign="center">
      <GridItem size="1/1">
        <Display1 element="h5" className="venue_info_name">
          {name}
        </Display1>
      </GridItem>
      <GridItem size={{ sm: '3/5' }}>
        <div className="pl0 venue_address">
          {address.split(',').map((p) => (
            <Body1 element="p" className="address_part" key={p}>
              {p}
            </Body1>
          ))}
        </div>
      </GridItem>
      <GridItem className="pl0" size={{ sm: '2/5' }}>
        <Link to={`/venues/venue/${id}`}>
          <FilledButton
            fullWidth
            type="button"
            className="activity_list_button"
          >
            <Display1 element="span">View venue</Display1>
          </FilledButton>
        </Link>
      </GridItem>
    </GridWrapper>
  )
}

export default MapInfo
