import React from 'react'
import CalendarDay from './CalendarDay'
import CalendarEmptyDays from './CalendarEmptyDays'
import CalendarHeader from './CalendarHeader'

const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

interface CalendarViewProps {
  sessions: Record<
    string,
    {
      remainingPlaces: number
      sessionId: number
    }
  >

  year: number
  month: number
  startDay?: number
  endDay?: number
}

const CalendarView: React.FC<CalendarViewProps> = ({
  sessions,
  year,
  month,
  startDay = 1,
  endDay = 31,
}) => {
  const currentDate = new Date()
  const startDate = new Date(year, month, startDay)
  const endDate = Math.min(
    new Date(year, month + 1, 0).getTime(),
    new Date(year, month, endDay).getTime()
  )

  const dates: Date[] = []

  let date
  const lastDate = new Date(endDate)
  for (
    date = new Date(startDate);
    date <= lastDate;
    date.setDate(date.getDate() + 1)
  ) {
    dates.push(new Date(date))
  }

  return (
    <div className="calendar__wrapper" data-test-id="calendar-view">
      <h4 className="calendar_month">{MONTHS[month]}</h4>
      <div className="calendar_header">
        <CalendarHeader />
      </div>
      <div className="calendar_days">
        <CalendarEmptyDays numberOfDays={startDate.getDay() - 1} />
        <CalendarDay
          sessions={sessions}
          currentDate={currentDate}
          dates={dates}
        />
      </div>
    </div>
  )
}

export default CalendarView
