import React from 'react'
import classnames from 'classnames'
import { getRemainingSpacesText } from '../../utils'

const isWeekend = (date: Date) => date.getDay() === 6 || date.getDay() === 0

interface CalendarDayProps {
  dates: Date[]
  sessions: Record<
    string,
    {
      remainingPlaces: number
      sessionId: number
    }
  >
  currentDate: Date
}

const CalendarDay: React.FC<CalendarDayProps> = ({
  dates,
  sessions,
  currentDate,
}) => {
  if (!dates) return null
  return (
    <>
      {dates.map((date: Date) => {
        const { [date.toDateString()]: sessionInfo } = sessions
        const remainingPlaces = sessionInfo?.remainingPlaces ?? 0
        const fallsOnWeekend = isWeekend(date)
        const isFullyBooked = remainingPlaces <= 0
        const isFutureDate = date >= currentDate
        const className = classnames('day', {
          weekend: fallsOnWeekend,
          valid: isFutureDate && !isFullyBooked && !fallsOnWeekend,
          full: !fallsOnWeekend && isFullyBooked,
        })
        const remainingPlacesText =
          fallsOnWeekend || !isFutureDate
            ? '\xa0'
            : getRemainingSpacesText(remainingPlaces)
        return (
          <div
            data-date={date.toDateString()}
            key={date.getTime()}
            className={className}
          >
            <h6 className="date">{date.getDate()}</h6>
            <span className="remaining_places">{remainingPlacesText}</span>
          </div>
        )
      })}
    </>
  )
}

export default CalendarDay
