const getRemainingSpacesText = (spaces: number) => {
  let renderedText = `${spaces} SPACES`
  switch (spaces) {
    case 0:
      renderedText = 'FULL'
      break
    case 1:
      renderedText = `1 SPACE`
      break
    default:
      break
  }
  return renderedText
}

export default getRemainingSpacesText
