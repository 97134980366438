import React from 'react'
import { useParams, Link } from '@reach/router'
import { GridWrapper, GridItem, Card } from '@jsluna/react'
import { useQuery } from 'react-query'
import axios from 'axios'
import Loader from '../Loader'
import BackButton from '../BackButton'
import VenueInfo from '../VenueInfo'
import CourseInfo from '../CourseInfo'
import useRedirect from '../useRedirect'
import config from '../../config'

const getVenue = async (_key: string, venueId: string) => {
  const { data } = await axios.get(
    `${config.apiEndpoint}${config.venuesPath}/${venueId}`
  )
  return data
}

const Venue: React.FC = () => {
  useRedirect()
  const params = useParams()
  const { status, data, error } = useQuery(
    params?.id && ['venue', params?.id],
    getVenue
  )

  if (!data && status === 'loading') return <Loader />
  if (error) return <p>An error has ocurred</p>
  return (
    <GridWrapper className="course_information__wrapper">
      <GridItem size="1/1">
        <Link to="/venues" style={{ textDecoration: 'none' }}>
          <BackButton />
        </Link>
      </GridItem>
      <GridItem size="1/1" className="course_info__wrapper ln-u-hard-ends">
        <Card className="ln-u-hard-ends ln-u-hard-top">
          <VenueInfo venue={data} />
        </Card>
      </GridItem>
      <GridItem size="1/1" className="course_info__wrapper">
        {data.courses.map(
          ({
            id,
            time_start,
            cost,
            full,
          }: {
            id: number
            time_start: string
            cost: string
            full: boolean
          }) => (
            <CourseInfo
              key={id}
              courseId={id}
              startTime={time_start}
              price={cost}
              full={full}
            />
          )
        )}
      </GridItem>
    </GridWrapper>
  )
}

export default Venue
