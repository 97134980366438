import * as yup from 'yup'

const MIN_LOCATION_LENGTH = 3
const MAX_LOCATION_LENGTH = 10

const venueSearchSchema = yup
  .object({
    location: yup
      .string()
      .min(MIN_LOCATION_LENGTH, `${MIN_LOCATION_LENGTH} characters minimum`)
      .max(MAX_LOCATION_LENGTH, `${MAX_LOCATION_LENGTH} characters maximum`)
      .required(),
  })
  .noUnknown(true)

export type VenueSearchDataType = yup.InferType<typeof venueSearchSchema>
export default venueSearchSchema
