import React from 'react'
import { ToggleButtonGroup, ToggleButton } from '@jsluna/react'

interface Button {
  value: string
  label: string
  icon: any
}
interface ToogleViewProps {
  state: string
  buttons: Button[]
  onChangeEvent: (value: string) => void
}

const ToogleView: React.FC<ToogleViewProps> = ({
  state,
  buttons,
  onChangeEvent,
}) => {
  return (
    <ToggleButtonGroup
      inline
      alpha
      label="List / Map"
      value={state}
      onChange={(_event: any, value: string) => onChangeEvent(value)}
    >
      {buttons.map(({ label, value, icon: Icon }) => (
        <ToggleButton
          key={value}
          value={value}
          data-testid={`${value}_view_button`}
        >
          <Icon className="ln-u-margin-right" />
          {label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

export default ToogleView
