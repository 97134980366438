import React from 'react'
import { GridItem } from '@jsluna/react'
import BookingButton from '../BookingButton'
import SessionCalendar from '../SessionCalendar'
import config from '../../config'

interface CourseInfoProps {
  courseId: number
  price: string
  startTime: string
  full: boolean
}

const CourseInfo: React.FC<CourseInfoProps> = ({
  courseId,
  startTime,
  price,
  full,
}) => {
  return (
    <>
      <div className="course_data__wrapper">
        <h6 className="course_data">Ages 5 - 15</h6>
        <h6 className="course_data">{`${startTime} - 4:00 PM`}</h6>
        <h6 className="course_data">{`Cost per day: £${price}`}</h6>
      </div>
      <GridItem className="course_description" size={{ sm: '1/2' }}>
        <h3>Course Description</h3>
        <p>
          Sainsbury’s Active Kids holiday clubs are designed to encourage kids
          to be more active and healthier in the summer holidays, at an
          affordable price for parents. We want our holiday clubs to be fun for
          everyone, so our Active Kids will be trying all sorts of activities
          from tennis, to arts & crafts, to circus skills!
        </p>
        <p>
          Throughout the day we’re also teaching kids important values including
          trust, respect and positive contribution, helping to build their
          confidence with new people and new activities. All of this is only £10
          per child per day, and includes a healthy lunch and snacks.
        </p>
        <h4>Timings</h4>
        <p>
          Our clubs run during the summer holidays, but dates do vary slightly
          by location and by availability so make sure to check this during the
          booking process.
        </p>
        <p>
          Clubs run Monday to Friday, 9am – 4pm, but registration opens at
          8.45am. Unfortunately we can’t look after kids before or after these
          times.
        </p>
        <h4>What will kids be doing?</h4>
        <p>
          Our aim is to encourage kids to try new sports and activities, and
          help them find new ways of keeping active that they enjoy. We’ve
          designed a program that includes a diverse range of traditional sports
          such as gymnastics, tennis, rounders, cricket, dodgeball, football and
          fencing.
        </p>
        <p>
          However, we are also including a variety of active play sessions which
          focus on games-based physical activity rather than traditional sports,
          and are designed to improve social skills, self-esteem, co-ordination
          and physical wellbeing. But it’s not all about sport, if your kids
          love being creative then our arts and crafts workshop will be great
          fun for them too.
        </p>
        <p>
          All activities are age appropriate and kids are spilt into suitable
          age groups when they arrive.
        </p>
        <h4>Health and Wellbeing</h4>
        <p>
          We know good health includes physical activity, a healthy diet, and
          mental wellbeing. Our Active Kids clubs provide a nutritionally
          healthy lunch and snacks for kids ages 5-15, and during lunchtime kids
          will also learn about nutritional wellbeing and how to build a healthy
          lunch themselves.
        </p>
        <p>
          <>
            Our Activity Professionals are fully insured and hold certifications
            in disability inclusion, emergency first aid and safeguarding and
            protecting children. However, unfortunately we cannot provide direct
            one-to-one care at this time, but can plan accordingly when
            receiving relevant information upon booking. Please give us a call
            on
          </>
          <a href="tel:01953499088"> 01953 499088</a>
          <> if you’d like to discuss your child’s needs specifically.</>
        </p>
        <h4>How to book</h4>
        <p>Booking is really simple:</p>
        <ol>
          <li>Select the club and days that you’d like your child to attend</li>
          <li>
            We’ll need to take some details from you including emergency contact
            details, and any allergy or medical issues
          </li>
          <li>Pay by card, or choose to use your Nectar points</li>
        </ol>
        <p>
          You can book up to 5 children at a time, and onto as many days as you
          like, subject to availability. However, we encourage kids to take part
          for a full week to ensure sure they experience all the great
          activities on offer.
        </p>
        <p>
          Once your booking is confirmed, you will receive an email containing
          your booking reference. Please keep this safe, as you’ll need it to
          register your child on their first day.
        </p>
      </GridItem>
      <GridItem size={{ sm: '1/2' }} className="course_calendar">
        <h3 className="course_description_header">Course Availability</h3>
        <SessionCalendar courseId={courseId} />
      </GridItem>
      <GridItem size={{ sm: '1/2' }} className="course_book_button">
        <BookingButton
          course={{ isFull: full }}
          url={`${config.premierSiteCourse}${courseId}`}
        />
      </GridItem>
    </>
  )
}

export default CourseInfo
