import { useEffect } from 'react'
import { useNavigate } from '@reach/router'
import config from '../../config'

const useRedirect = () => {
  const navigate = useNavigate()
  const { isSessionOpen } = config
  useEffect(() => {
    if (!isSessionOpen) {
      navigate('/contact-form', { replace: true })
    }
  }, [navigate, isSessionOpen])
}

export default useRedirect
