import React, { useState } from 'react'
import { Marker, InfoWindow } from '@react-google-maps/api'
import MapInfo from './MapInfo'
import markerIcon from '../../images/map_marker.png'

interface MapMarkerProps {
  id: number
  name: string
  address: string
  lat: number
  lng: number
  showInfo: boolean
}

const MapMarker: React.FC<MapMarkerProps> = ({
  lat,
  lng,
  name,
  address,
  id,
  showInfo,
}) => {
  const [isOpen, toogleMarker] = useState(false)
  return (
    <Marker
      icon={markerIcon}
      onClick={() => toogleMarker(true)}
      position={{ lat, lng }}
    >
      {isOpen && showInfo && (
        <InfoWindow
          position={{ lat, lng }}
          onCloseClick={() => toogleMarker(false)}
        >
          <MapInfo name={name} address={address} id={id} />
        </InfoWindow>
      )}
    </Marker>
  )
}

export default MapMarker
