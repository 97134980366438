import React from 'react'

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

const CalendarHeader: React.FC = () => {
  return (
    <>
      {days.map((day) => (
        <h6
          key={day}
          className={`day ${day}`}
          data-testid="calendar-header-day"
        >
          {day}
        </h6>
      ))}
    </>
  )
}

export default CalendarHeader
