import React from 'react'
import { useQuery } from 'react-query'
import Axios from 'axios'
import Loader from '../Loader'
import { getCalendarViewData } from '../../utils'
import CalendarView from './CalendarView'
import config from '../../config'

export const parseCourseSessions = (sessions: any) => {
  return sessions.reduce((accumulator: any, session: any) => {
    const { id, date, course_id, remaining_places } = session
    if (!course_id) {
      return accumulator
    }
    if (!accumulator[course_id]) {
      accumulator[course_id] = {}
    }

    const newDate = new Date(Date.parse(date)).toDateString()

    accumulator[course_id][newDate] = {
      remainingPlaces: remaining_places,
      sessionId: id,
    }
    return accumulator
  }, {})
}

const getCourse = async (_key: string, courseId: string) => {
  const { data } = await Axios.get(
    `${config.apiEndpoint}${config.coursesPath}/${courseId}`
  )
  return data
}

interface SessionCalendarProps {
  courseId: number
}

const SessionCalendar: React.FC<SessionCalendarProps> = ({ courseId }) => {
  const { data, error, status } = useQuery(
    courseId && ['course', courseId],
    getCourse
  )

  if (status === 'loading') {
    return <Loader show />
  }
  if (error) {
    return <h5 className="center-text mt2">An error has ocurred</h5>
  }
  if (!data) return null
  const { days, date_start, date_end } = data
  const calViewData = getCalendarViewData(date_start, date_end)
  const courseSessions = parseCourseSessions(days)
  const { [courseId]: sessions } = courseSessions

  return (
    <div data-testid="session-calendar">
      {calViewData.map((content) => {
        return (
          <CalendarView
            key={content.month}
            sessions={sessions}
            month={content.month}
            startDay={content.startDay}
            year={content.year}
            endDay={content.endDay}
          />
        )
      })}
    </div>
  )
}

export default SessionCalendar
